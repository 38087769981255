/* stylelint-disable max-nesting-depth, no-descending-specificity */
@import '@/core/tamagoshi/scss';
@import '@/domains/productDiscovery/HeaderLeonidas/styles/commons';

$borderSize: $spacingXXs;
$borderRadius: 28px;
$padding: $spacingSm;
$desktopLeftColumnWidth: 288px;
$desktopLeftColumnHeight: 480px;
$desktopProductButtonHeight: 40px;
$mobileProductButtonHeight: 88px;
$flexibleHeight: calc(100vh - 180px);
$inputHeight: 60px;
$maxHeightDesktop: calc(#{$flexibleHeight} + #{$borderSize} + #{$padding});

.root.visible {
  background-color: $n0;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  width: 100%;

  @include size-below('md') {
    position: fixed;
    top: $mobileSearchBarHeight;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include size-above('md') {
    border: $borderRadius solid transparent;
    border-bottom-width: 0;
    border-left-width: calc($borderRadius - $borderSize);
    border-right-width: calc($borderRadius / 2);
    border-radius: 0 0 $borderRadius $borderRadius;
    left: 0;
    max-height: $maxHeightDesktop;
    padding: 0 calc($borderRadius / 2) 0 0;
    position: absolute;
    top: calc($inputHeight / 2);
    z-index: -1;
  }
}

.wrapper {
  list-style: none;
  padding: $spacingXs 0;
  margin: 0 $spacingSm;
  display: flex;
  flex-direction: column;
  height: calc(100% - $spacingMd);

  &.autocompleteDesign {
    > li:last-of-type {
      @include size-below('md') {
        padding-bottom: $suggestionsWrapperMarginBottom;
      }
    }

    padding-bottom: 0;

    .suggestionBlock:nth-last-child(2) {
      flex: 1;
    }
  }

  @include size-above('md') {
    display: grid;
    column-gap: $spacingMd;
    grid-template-columns: $desktopLeftColumnWidth 1fr;
    grid-template-areas:
      'suggestions products'
      'suggestions products'
      'suggestions products'
      'suggestions products';
    padding: 0;
    margin: $spacingSm 0;
    height: auto;
    will-change: max-height;
  }

  .productSuggestions {
    grid-area: products;
  }
}

.autocompleteDesign {
  .suggestionBlock:nth-last-child(2) {
    flex: 1;
  }
}

.multipleProductsLists {
  > * {
    margin-top: $spacingLg;

    &:first-child {
      margin-top: 0;
    }
  }
}

.root:not(.visible) .wrapper {
  display: none;
}

.suggestionBlock {
  margin-bottom: $spacingXs;
  grid-column: 1;

  @include size-above('md') {
    &.productSuggestions {
      margin-top: 0;
    }
  }
}
